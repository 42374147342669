<template>
  <div>
    <v-breadcrumbs :items="crumbs"></v-breadcrumbs>
    <base-loader :isLoading="isLoading"></base-loader>
    <v-row>
      <v-col md="6">
        <v-card>
          <v-card-text>
            <h4 class="text-md-h4 primary--text mb-5">Company Details</h4>
            <v-simple-table v-if="$route.params.id != 0">
              <tbody>
                <tr>
                  <td>Company Name</td>
                  <td>
                    {{ companyModel.name ? companyModel.name : "" }}
                  </td>
                </tr>
                <tr>
                  <td>Company Id</td>
                  <td>
                    {{ companyModel.id ? companyModel.id : "" }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>

            <v-btn
              v-if="$route.params.id == 0"
              color="primary"
              large
              :to="`${$route.path}/edit`"
              class="mr-2"
              >Create a New Company</v-btn
            >
            <v-btn
              v-else
              large
              color="primary"
              :to="`${$route.path}/edit`"
              class="mr-2"
              >Edit</v-btn
            >
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { Company } from "@/models/Company";
import { gql } from "apollo-boost";
export default {
  data() {
    return {
      isEdit: false,
      companyModel: new Company(),
      me: "",
      search: null,
      crumbs: [
        {
          text: "Companies",
          disabled: false,
          href: "/consultant/companies",
        },
        {
          text: "Company Details",
          disabled: false,
          href: `/consultant/company/${this.$route.params.id}`,
        },
      ],
    };
  },
  created() {},
  apollo: {
    me: gql`
      query {
        me {
          id
        }
      }
    `,
    company: {
      query: gql`
        query Company($id: ID!) {
          company(id: $id) {
            id
            name
            createdBy
            createdAt
            updatedAt
          }
        }
      `,
      fetchPolicy: "network-only",
      skip() {
        return !this.$route.params.id || this.$route.params.id == 0;
      },
      variables() {
        const self = this;
        return {
          id: self.$route.params.id,
          createdBy: self.me.id,
        };
      },
      watchLoading(isLoading) {
        this.isLoading = isLoading;
      },
      result(response, key) {
        this.isLoading = false;
        this.companyModel.id = this.company.id;
        this.companyModel.name = this.company.name;
        this.companyModel.createdBy = this.company.createdBy;
      },
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
